'use client';
import { useEffect, useRef, useState } from 'react';
import classes from './HeroSection.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import MultiCarousel from '@/components/Carousels/MultiCarouselAlsoLike';
import Image from 'next/image';
import PlaceHolder from '@/components/Carousels/PlaceHolder';
import algoliasearch from 'algoliasearch';
import { ChevronRight } from 'react-feather';

const client =
	process.env.NEXT_PUBLIC_OG_DB === 'true'
		? algoliasearch(
				process.env.NEXT_PUBLIC_ALGOLIA_APPID,
				process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
		  )
		: algoliasearch(
				process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_APPID,
				process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_API_KEY
		  );
const HeroSection = () => {
	const [activeSection, setActiveSection] = useState(0);
	const [timerDuration, setTimerDuration] = useState(8000); // Timer duration remains constant
	const [selectedSection, setSelectedSection] = useState();
	const [loading, setLoading] = useState(false);
	const [listings, setListings] = useState({}); // Store listings for each section

	const intervalRef = useRef(null);

	const [windowWidth, setWindowWidth] = useState();

	useEffect(() => {
		setWindowWidth(window.innerWidth);
	}, []);

	const rotatingSections = [
		{
			key: '1',
			link: '/collections',
			filters: {
				sort: 'relevance',
			},
			text: {
				title: 'Better Than New',
				title1: 'Furniture & Home Decor',
				description:
					windowWidth > 600
						? "Save up to 80% on top brands, unique & designer pieces and more. 100's of pieces added daily. Get in quick to own the only one."
						: "Save up to 80% on top brands, unique finds, designer pieces & more. 100's of pieces added daily.",
			},
			imageSection: [
				{
					src: 'https://ik.imagekit.io/kuj9zqlrx0or/hero_landing/hero_4.0.png',
					alt: 'Quality Used Furniture, Delivered Nationwide - Kashew',
				},
			],
		},
		{
			key: '2',
			link: '/style/mid_century_modern',
			filters: {
				sort: 'relevance',
				style: 'mid_century_modern',
			},

			text: {
				title: 'Mid Century Modern',
				title1: 'Collection',
				description:
					'Authentic pieces from iconic designers & teak wood furniture from the 50s, 60s and 70s.',
			},
			imageSection: [
				{
					src: 'https://ik.imagekit.io/kuj9zqlrx0or/hero_landing/hero_1.2.png',
					alt: 'Quality Used Furniture, Delivered Nationwide - Kashew',
				},
			],
		},
		{
			key: '3',
			link: '/brand?op=branded',
			filters: {
				sort: 'relevance',
				option: 'branded',
			},

			text: {
				title: 'Branded Furniture',
				title1: 'Collection',
				description:
					windowWidth > 600
						? 'West Elm, CB2, Restoration Hardware, Crate & Barrel, Pottery Barn, Room & Board, DWR & more up to 80% off retail.'
						: 'West Elm, CB2, RH, Crate & Barrel, Pottery Barn, Room & Board, DWR & more up to 80% off retail.',
			},
			imageSection: [
				{
					src: 'https://ik.imagekit.io/kuj9zqlrx0or/hero_landing/hero_5.1.png',
					alt: 'Quality Used Furniture, Delivered Nationwide - Kashew',
				},
			],
		},
		{
			key: '4',
			link: '/style/vintage',
			filters: {
				sort: 'relevance',
				style: 'vintage',
			},

			text: {
				title: 'Vintage Wonderland',
				title1: 'Collection',
				description:
					'One of a kind pieces & unique finds, infused with decades of character and vintage charm.',
			},
			imageSection: [
				{
					src: 'https://ik.imagekit.io/kuj9zqlrx0or/hero_landing/hero_6.0.png',
					alt: 'Quality Used Furniture, Delivered Nationwide - Kashew',
				},
			],
		},
		{
			key: '5',
			link: '/collections/seating/sofas',
			filters: {
				sort: 'relevance',
				category: 'seating',
				subCategory: 'sofas',
			},

			text: {
				title: 'Sofas To Sink Into',
				title1: 'Collection',
				description:
					"Sofas for every style, size & budget. From leather to linen, velvet to wool, we've got you covered.",
			},
			imageSection: [
				{
					src: 'https://ik.imagekit.io/kuj9zqlrx0or/hero_landing/hero_3.1.png',
					alt: 'Quality Used Furniture, Delivered Nationwide - Kashew',
				},
			],
		},
		{
			key: '6',
			link: '/collections/storage',
			filters: {
				sort: 'relevance',
				category: 'storage',
			},

			text: {
				title: 'Storage Solutions',
				title1: 'Collection',
				description:
					'Dressers, chests of drawers, sideboards, credenzas, wardrobes & more.',
			},
			imageSection: [
				{
					src: 'https://ik.imagekit.io/kuj9zqlrx0or/hero_landing/hero_2.0.png',
					alt: 'Quality Used Furniture, Delivered Nationwide - Kashew',
				},
			],
		},
	];

	const getListingsHandler = async () => {
		setLoading(true);

		// Extract exactly 6 filter sets from rotatingSections
		const filters = rotatingSections
			.slice(0, 6)
			.map((section) => section.filters);

		try {
			const limit = windowWidth < 600 ? 2 : 5;

			// Ensure there are exactly 6 filter sets
			if (!Array.isArray(filters) || filters.length !== 6) {
				return;
			}

			// Initialize an empty array to store the results for each filter set
			let allListings = [];

			// Process each filter set individually
			for (let i = 0; i < filters.length; i++) {
				const filterSet = filters[i];

				const { style, category, subCategory, type, option, sort } = filterSet;

				// Convert filter values to lowercase for case-insensitive matching
				const categoryLower = category ? category.toLowerCase() : null;
				const subCategoryLower = subCategory ? subCategory.toLowerCase() : null;
				const typeLower = type ? type.toLowerCase() : null;
				const styleLower = style ? style.toLowerCase() : null;

				const index = client.initIndex(`kashew_listings`);

				let listings = await index
					.search('', {
						hitsPerPage: limit ? limit : 5,
						page: 0,
						filters: `status:active${
							option === 'branded' ? ` AND brandExists:1` : ''
						}${
							styleLower
								? ` AND (velouAttributes.styles:"${styleLower
										.replace(/-/g, ' ')
										.replace(
											/\band\b/g,
											'&'
										)}" OR velouAttributes.styles:"${styleLower
										.replace(/-/g, ' ')
										.replace(/\band\b/g, '&')}")`
								: ''
						}${typeLower ? ` AND newSchema.types:"${typeLower}"` : ''}${
							subCategoryLower
								? ` AND newSchema.subCategories:"${subCategoryLower}"`
								: ''
						}${
							categoryLower
								? ` AND newSchema.categories:"${categoryLower}"`
								: ''
						}`,
						facets:
							'title,status,newSchema,brand,price,date,images,estRetailPrice,favorites,kashewId,relevance,velouAttributes,freeShipping,delivery',
						clickAnalytics: true,
					})
					.then(async (res) => {
						return res.hits;
					})
					.catch((err) => {
						console.log(err);
					});

				// Add the listings for the current filter set to the final array
				allListings.push({
					filterSet: i + 1, // Just a label to differentiate the filter sets
					listings,
				});
			}

			// Map the response data to each section's key
			const listingsBySection = rotatingSections.reduce((acc, section, idx) => {
				acc[section.key] = allListings[idx]?.listings || []; // Get the listings for this section or an empty array
				return acc;
			}, {});

			setListings(listingsBySection);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getListingsHandler(); // Fetch all listings once on component mount
	}, []);

	// Function to start the interval with the current timerDuration
	const startInterval = (duration) => {
		clearInterval(intervalRef.current); // Clear any existing interval

		intervalRef.current = setInterval(() => {
			setActiveSection((prev) =>
				prev < rotatingSections.length - 1 ? prev + 1 : 0
			);
			setSelectedSection(null); // Reset selectedSection after automatic rotation
			setTimerDuration(8000); // Revert to 8000ms after custom duration ends
			startInterval(8000); // Reset interval to 8000ms after custom duration
		}, duration || timerDuration);
	};

	// Automatically start the timer on component mount
	useEffect(() => {
		startInterval(timerDuration);

		return () => clearInterval(intervalRef.current); // Cleanup on unmount
	}, []);

	// Handle section change on button click
	const handleSectionChange = (index, customDuration, selectIndex = null) => {
		setActiveSection(index); // Set the active section to the clicked one

		if (selectIndex !== null) {
			setSelectedSection(selectIndex); // Set the selected section when button is clicked
		} else {
			setSelectedSection(null); // Reset selectedSection after automatic rotation
		}

		clearInterval(intervalRef.current); // Clear the current interval
		setTimerDuration(customDuration); // Set the custom timer duration
		startInterval(customDuration); // Restart the interval with the new duration
	};

	const currentSection = rotatingSections[activeSection];
	const currentListings = listings[currentSection.key] || [];

	return (
		<section className={classes.main}>
			<section className={classes.mainBanner}>
				<header className={classes.header}>
					<section className={classes.leftSection}>
						<AnimatePresence>
							<motion.section
								className={classes.textContainer}
								key={currentSection.text.title}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.6 }}
							>
								<Link className={classes.link} href={currentSection.link}>
									<motion.h1 className={classes.h1}>
										<motion.span className={classes.h1Colored}>
											{currentSection.text.title}
										</motion.span>{' '}
										{currentSection.text.title1}
									</motion.h1>
								</Link>
								<motion.p className={classes.p}>
									{currentSection.text.description}
								</motion.p>
								<Link href={currentSection.link} className={classes.buyButton}>
									Explore Collection
								</Link>{' '}
							</motion.section>
						</AnimatePresence>
					</section>

					<section className={classes.rightSection}>
						<Link className={classes.imageBlock} href={currentSection.link}>
							<AnimatePresence>
								<motion.section
									className={classes.imageContainer}
									key={currentSection.imageSection[0].src}
									initial={{ opacity: 0, y: 300 }}
									animate={{ opacity: 1, y: 0 }}
									exit={{ opacity: 0, y: -300 }}
									transition={{ duration: 0.6 }}
								>
									{currentSection.imageSection.map((image, index) => (
										<Image
											width={550}
											height={310}
											priority={true}
											key={index}
											className={classes.image}
											src={image.src}
											alt={image.alt}
										/>
									))}
								</motion.section>
							</AnimatePresence>
						</Link>
					</section>
				</header>
				<section className={classes.progressBarSection}>
					{rotatingSections.map((_, index) => (
						<button
							aria-label="switch section button"
							key={index}
							onClick={() => handleSectionChange(index, 16000, index)} // Set custom duration to 16000ms on click
							className={classes.buttonContainer}
						>
							<span className={classes.pagerItem}>
								<span
									className={
										activeSection === index && selectedSection !== index
											? classes.pagerItemInnerActive
											: activeSection > index || selectedSection === index
											? classes.pagerItemInnerDone
											: classes.pagerItemInner
									}
								></span>
							</span>
						</button>
					))}
					<button
						className={classes.nextButton}
						aria-label="next button"
						onClick={() =>
							handleSectionChange(
								activeSection < rotatingSections.length - 1
									? activeSection + 1
									: 0,
								8000 // Go back to 8000ms when manually clicking the next button
							)
						}
					>
						<ChevronRight className={classes.nextIcon} />
					</button>
				</section>
			</section>
			<AnimatePresence>
				<section className={classes.productGrid}>
					<motion.section
						key={currentSection.key}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.6 }}
						className={classes.productContainer}
					>
						{currentListings.length < 1 || loading ? (
							<PlaceHolder />
						) : (
							<MultiCarousel
								listings={currentListings}
								nextHandlerClick={() =>
									handleSectionChange(
										activeSection < rotatingSections.length - 1
											? activeSection + 1
											: 0,
										8000 // Go back to 8000ms when manually clicking the next button
									)
								}
								nextHandlerClickPrev={() =>
									handleSectionChange(
										activeSection > 0
											? activeSection - 1
											: rotatingSections.length - 1,
										8000 // Go back to 8000ms when manually clicking the next button
									)
								}
							/>
						)}
					</motion.section>
				</section>
			</AnimatePresence>
		</section>
	);
};

export default HeroSection;
